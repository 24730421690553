<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="editEmployeeDepartment"
              v-if="employee_department && onProgress === false">

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="title">
                Название <span class="req"/>
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('title') }">
                <input class="form-control"
                       id="title"
                       name="title"
                       required
                       type="text"
                       v-model="form.title">
                <HasError :form="form" field="title"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Филиал
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('branch_id') }">
                <multiselect :allow-empty="true"
                             :options="branches"
                             :searchable="false"
                             :show-labels="true"
                             @input="() => {
                               form.branch_id = branch ? branch.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Выберите филиал"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             track-by="id"
                             v-model="branch">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.title }}, {{ props.option.address }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                  </template>
                </multiselect>
                <HasError :form="form" field="branch_id"/>
              </div>
            </div>
          </div>

          <br/>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <Button :disabled="!validateData || form.busy"
                  type="submit"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-warning pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-edit"></i>
          </Button>
        </form>
        <loading-processing v-else/>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import Multiselect from "vue-multiselect";
import BranchService from "@/services/BranchService";

Form.axios = API.apiClient;

export default {
  name: 'edit',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: [
    'employeeDepartmentId',
  ],

  data: () => ({
    page_title: 'Изменить отдел',

    form: null,

    employee_department: null,

    branches: [],
    branch: null,

    onProgress: true,
  }),

  async created() {
    try {
      const employee_department_response = await API.apiClient.get(
        '/employees/department/' + this.employeeDepartmentId
      );

      this.employee_department = employee_department_response.data;

      this.branches = await BranchService.getItems();
      this.branch = this.employee_department.branch ? this.employee_department.branch : null;

      this.form = new Form({
        title: this.employee_department.title,
        branch_id: this.employee_department.branch ? this.employee_department.branch.id : null,
      });
    } catch (error) {
      errorHandler(error);
    }
    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.title;
    },
  },

  methods: {
    async editEmployeeDepartment() {
      await this.form.put('/employees/department/' + this.employeeDepartmentId + '/edit').then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/employees/departments'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
