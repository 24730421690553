import Cookies from "js-cookie";

export default {
    getItems(relations, relations_count, search_text, filters_query) {
        let branches = JSON.parse(Cookies.get("branches") ?? null);

        if (!branches) {
            branches = this.loadItems(
                relations,
                relations_count,
                search_text,
                filters_query
            );
        }

        return branches;
    },
    async loadItems(relations, relations_count, search_text, filters_query) {
        try {
            const filter_request = await API.handleFilterRequest(
                relations,
                relations_count,
                search_text,
                filters_query
            );
            const branches_response = await API.apiClient.get("/branches", {
                params: {
                    relations: filter_request.relations,
                    relations_count: filter_request.relations_count,
                    search_text: filter_request.search_text,
                    filters_query: filter_request.filters_query,
                },
            });

            if (
                branches_response &&
                branches_response instanceof Object &&
                branches_response.data
            ) {
                Cookies.set(
                    "branches",
                    JSON.stringify(branches_response.data),
                    {
                        expires: 7,
                    }
                );

                return branches_response.data;
            } else {
                return null;
            }
        } catch (error) {
            errorHandler(error);
        }
    },
};
