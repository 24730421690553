<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="saveEmployeeDepartment" v-if="onProgress === false">
          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="title">
                Название <span class="req"/>
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('title') }">
                <input class="form-control"
                       id="title"
                       name="title"
                       required
                       type="text"
                       v-model="form.title">
                <HasError :form="form" field="title"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Филиал
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('branch_id') }">
                <multiselect :allow-empty="true"
                             :options="branches"
                             :searchable="false"
                             :show-labels="true"
                             @input="() => {
                               form.branch_id = branch ? branch.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Выберите филиал"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             track-by="id"
                             v-model="branch">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.title }}, {{ props.option.address }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                  </template>
                </multiselect>
                <HasError :form="form" field="branch_id"/>
              </div>
            </div>
          </div>

          <br/>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </form>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from 'vform';
import Multiselect from 'vue-multiselect';
import BranchService from "@/services/BranchService";

Form.axios = API.apiClient;

export default {
  name: 'create',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Добавить отдел',

    form: new Form({
      title: null,
      branch_id: null,
    }),

    branches: [],
    branch: null,

    onProgress: true,
  }),

  async created() {
    try {
      this.branches = await BranchService.getItems();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.title;
    },
  },

  methods: {
    async saveEmployeeDepartment() {
      await this.form.post('/employees/departments/add').then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/employees/departments'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
