var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',[_c('previous-route-block'),_vm._v(" "+_vm._s(_vm.page_title)+" "),_c('router-link',{staticClass:"btn btn-success pull-right",attrs:{"to":{ name: 'employees.departments.create' },"title":"Добавить","type":"button"}},[_c('i',{staticClass:"fa fa-plus"})])],1)]),_c('div',{staticClass:"box-body"},[(_vm.departments && _vm.onProgress === false)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed"},[_vm._m(0),_c('tbody',_vm._l((_vm.departments),function(department){return _c('tr',[_c('td',{staticClass:"nowrap-column font-medium"},[_vm._v(" "+_vm._s(department.title)+" ")]),_c('td',{staticClass:"nowrap-column"},[(department.branch)?[_vm._v(" "+_vm._s(department.branch.title)+", "+_vm._s(department.branch.address)+" ")]:[_vm._v(" — ")]],2),_c('td',{staticClass:"nowrap-column"},[_c('router-link',{staticClass:"btn btn-primary",staticStyle:{"margin":"3px"},attrs:{"to":{
                           name: 'employees.departments.edit',
                           params: {
                             employeeDepartmentId: department.id
                           }
                         },"title":"Редактировать"}},[_c('i',{staticClass:"fa fa-edit"})])],1)])}),0)])]):_c('loading-processing')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Название")]),_c('th',[_vm._v("Филиал")]),_c('th')])])
}]

export { render, staticRenderFns }