<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}

        <router-link :to="{ name: 'employees.departments.create' }" class="btn btn-success pull-right"
                     title="Добавить" type="button">
          <i class="fa fa-plus"></i>
        </router-link>
      </h3>
    </div>

    <div class="box-body">
      <div class="table-responsive" v-if="departments && onProgress === false">
        <table class="table table-condensed">
          <thead>
          <tr>
            <th>Название</th>
            <th>Филиал</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="department in departments">
            <td class="nowrap-column font-medium">
              {{ department.title }}
            </td>
            <td class="nowrap-column">
              <template v-if="department.branch">
                {{ department.branch.title }}, {{ department.branch.address }}
              </template>
              <template v-else>
                —
              </template>
            </td>
            <td class="nowrap-column">
              <router-link :to="{
                             name: 'employees.departments.edit',
                             params: {
                               employeeDepartmentId: department.id
                             }
                           }"
                           class="btn btn-primary"
                           style="margin: 3px;"
                           title="Редактировать">
                <i class="fa fa-edit"></i>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>

</template>

<script>
export default {
  name: "departments",

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Отделы",

    departments: [],

    onProgress: true,
  }),

  async created() {
    try {
      const departments_response_items = await API.apiClient.get("/employees/departments");

      this.departments = departments_response_items.data;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {},
};
</script>

<style scoped>

</style>
